import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { authService } from './auth'

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ (props) => {

        const user = authService.getUser();

        return user != null
            ? <Component { ...props } />
            : <Redirect push to={{
                pathname: '/login',
                state: {
                    next: props.location.pathname.startsWith("/jobs") || props.location.pathname.startsWith("/conversations") ? props.location.pathname : "/",
                },
            }} />
    }} />
);
