import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';

import { authService } from '../../utils/auth';

import Loading from "../shared/Loading";
import {FormControlLabel, Grid, Hidden, Link, Paper, TextField} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

import LogoImage from '../../assets/logo.svg';
import BackgroundImage from '../../assets/19276.jpg';
import {tenantUtil} from "../../utils/tenant";

const styles = theme => ({
    root: {
        position: "absolute",
        minHeight: "100%",
        height: "100%",
        width: "100%",
    },
    grid: {
        minHeight: "100%",
        height: "100%",
    },
    left: {
        minHeight: "100%",
        height: "100%",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: 'cover',
        backgroundColor: "white",
    },
    right: {
        paddingTop: "10.8rem",
        paddingLeft: "4.8rem",
        paddingRight: "4.8rem",
        display: "flex",
        flexDirection: "column",
    },
    logoContainer: {
        height: "106px",
        width: "100px",
        backgroundImage: `url(${LogoImage})`,
        backgroundSize: 'cover',
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "60px",
    },
    loginTitle: {
        marginBottom: "2.2rem",
    },
    loginTermsWrapper: {
        paddingTop: "20px",
        margin: "0 auto",
    },
    fill: {
        flex: "1 1 auto",
    },
    credentialsFormHolder: {
      paddingTop: "10px",
    },
    submit: {
        marginTop: "13px",
    },
    footer: {
        textAlign: "center",
        paddingBottom: "10px",
    }
});


class Login extends Component {

    constructor(props) {
        super(props);



        this.loadingChecker  = setInterval(() => {

            if(!authService.isLoginInProgress()) {
                this.setState({
                    loading: false,
                });
            }
        }, 100);

        let and = {};

        const string_location = window.localStorage.getItem("redirect_to");

        if(string_location) {

            const redirect = JSON.parse(string_location);
            and.redirect = redirect;
        }

        this.state = {
            loading: true,
            ...and,
        };
    }

    componentDidMount() {
    }


    componentWillUnmount() {
        window.localStorage.removeItem("redirect_to")
    }

    doLogin = () => {

        this.setState({
            error: undefined,
        });

        const next = {
            location: this.props.location.state?.next || "/",
            expire: new Date().getTime() + (60000 * 5)
        };

        window.localStorage.setItem("redirect_to", JSON.stringify(next))

        authService.loginWithGoogle();
    };

    render() {

        let { classes, location } = this.props;
        let { redirect, error } = this.state;

        if(this.state.loading) {

            return (
                <Loading />
            );
        } else {

            if(this.loadingChecker) {
                clearInterval(this.loadingChecker);
                this.loadingChecker = null;
            }

            if(authService.getUser()) {

                let next = "/";
                if(redirect) {
                    next = redirect.location;
                } else if(location && location.state && location.state.next) {
                    next = location.state.next;
                }

                if(!next) next = "/";

                return (<Redirect to={next} />);
            } else {

                return (
                    <div className={classes.root}>
                        <Grid container className={classes.grid}>
                            <Hidden xsDown>
                            <Grid item xs={12} sm={4} md={6} lg={8} className={classes.left}>
                            </Grid>
                            </Hidden>
                            <Grid item xs={12} sm={8} md={6} lg={4} className={classes.right}>
                                <div className={classes.logoContainer}>
                                </div>
                                <div>

                                <Typography variant="h6" className={classes.loginTitle}>
                                    <FormattedMessage id="account.login" defaultMessage={`Login to your account`} />
                                </Typography>
                                <Typography color={"error"}>{ error }</Typography>
                                </div>

                                <div>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.loginBtn}
                                        onClick={ this.doLogin }>
                                        <FormattedMessage id="login" defaultMessage={`Login with GSuite`} />
                                    </Button>
                                </div>
                                <br />

                                <div className={classes.loginTermsWrapper}>
                                    <Typography variant="p" className={classes.loginTerms}><FormattedMessage id="terms" defaultMessage={`Terms and conditions`} /></Typography>
                                </div>

                                <div className={ classes.fill } />
                                <div className={ classes.footer }>Copyright © Decoded Health</div>
                            </Grid>
                        </Grid>
                    </div>
                );
            }
        }
    }
}

export default withStyles(styles)(Login);
