import firebase from 'firebase/app';
import 'firebase/auth';

export class MockAuthService {

    constructor() {

        this.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ik4tbEMwbi05REFMcXdodUhZbkhRNjNHZUNYYyJ9.eyJhdWQiOiI0ODU2NzAyNy05ZDJlLTQ3YWItOWQ4Yy0xMmQ2MDA5MWYwNzgiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vY2NiNmI1MmItMGFkYi00Y2ZjLWE2MDQtNTdiMDI5YWFjMTBlL3YyLjAiLCJpYXQiOjE1NTQ3NTYyOTEsIm5iZiI6MTU1NDc1NjI5MSwiZXhwIjoxNTU0NzYwMTkxLCJhaW8iOiJBVFFBeS84TEFBQUEwaXBvRFo5RlJzTFBESlM0VFo3M0E3TzdUQzRCSnVXWVNsUVZHSGdhNkVIMWF1bGFBVEpVYTBVaTE2d0Vta291IiwibmFtZSI6IktldmluIEJheWVzIiwibm9uY2UiOiJlZDZkZmZlMy04MmU0LTRhMTItYmNlMS0yZDUwYzAzY2ZkMmQiLCJvaWQiOiJkODE1NTQyMC1kNmVlLTQyZmMtYjRiMS1hNzA3ZGFiY2YxMTgiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJLZXZpbkBkZWNvZGVkaGVhbHRoLmNvbSIsInN1YiI6IkRmM0pQNHkxb2liSmx1VkplTXVOTmNiejZJaG5BYVJDS1N3NDFYNFVWYk0iLCJ0aWQiOiJjY2I2YjUyYi0wYWRiLTRjZmMtYTYwNC01N2IwMjlhYWMxMGUiLCJ1dGkiOiJKZXJVNlh0cmNFR3BueW1ZZGZaSkFBIiwidmVyIjoiMi4wIn0.dByOoc6N0NUf4QTfrqSr1Qjw2xeSyVxMlYscz9_TXAR8Bu2oIGWWJU66GbREocjL7DyJdS1L7UbSEXXDNziL3U3uXnr8-ZF3uuyAt3qG03L_wVNcEKz9yRDFgvBafOhxcp-pdjcfBqD8vqOnL-DplHYdczN9AGY2ueE2JIjU8JdtTaWTor7W4T-nR40XfavvGRlogamDQ9j-raZHaR0BQqbZvw91lWrOGfDUbmzWQb0mK9uutv4pt224ywRO-AxCXcWTTUeomsK5ZqMSFDjv3q7nNShF-jKcF29W5JrXeYddEtaEP0KRI6siw0-273B8Xm6SNxFhPtXf4-qaqdRsYw";
        this.user = {
            id: "d8155420-d6ee-42fc-b4b1-a707dabcf118",
            email: "kevin@local",
        };
        this.loginInProgress = false;
    }

    login = () => {

        this.loginInProgress = true;
    };

    logout = () => {

        this.token = undefined;
        this.user = undefined;
    };

    getRoles = () => {

        return new Promise((resolve, reject) => {
            resolve(["admin","medical","annotator"])
        });
    };

    loginInProgress = () => {

        return this.app.loginInProgress();
    };


    getUser = () => {

        return this.user;
    };

    getToken = () => {

        let $token = this.token;

        return new Promise((resolve, reject) => {

            setTimeout(() => {

                resolve($token);
            }, 100);
        });
    }
}


export default class AuthService {

    constructor() {

        let config = {
            apiKey: process.env.REACT_APP_AUTH_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        };

        firebase.initializeApp(config);

        this.auth = firebase.auth();

        this.googleProvider = new firebase.auth.GoogleAuthProvider();
        this.googleProvider.addScope('profile');
        this.googleProvider.addScope('email');
        this.googleProvider.addScope('openid');
        this.loginInProgress = true;

        this.auth.getRedirectResult().then((result ,test) => {

            this.loginInProgress = false;
            if (result.credential) {
                this.token = result.credential.accessToken;
            }
            this.user = result.user;
        }).catch((error) => {

            // // Handle Errors here.
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // // The email of the user's account used.
            // var email = error.email;
            // // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // // ...
            console.error(error)
        });

    }

    loginWithGoogle = () => {

        this.auth.signInWithPopup(this.googleProvider).then((result) => {

            this.loginInProgress = false;
            if (result.credential) {
                this.token = result.credential.accessToken;
            }
            this.user = result.user;
            window.location = "/";
        }).catch((error) => {

            // // Handle Errors here.
            // var errorCode = error.code;
            // var errorMessage = error.message;
            // // The email of the user's account used.
            // var email = error.email;
            // // The firebase.auth.AuthCredential type that was used.
            // var credential = error.credential;
            // // ...
            console.error(error)
        });
    }

    logout = () => this.auth.signOut();

    getUser = () => {
        const user = this.auth.currentUser;
        return user;
    }

    isLoginInProgress = () => {
        const loginInProgress = this.loginInProgress;
        return loginInProgress;
    }

    getRoles = () => {

        return this.getTokenResult()
            .then((tokenResult) => {
                return tokenResult.claims.roles || [];
            }).catch(reason => []);
    };

    getTokenResult = () => {

        return this.auth.currentUser
            .getIdTokenResult()
            .then((token) => {

                if(!token) {
                    window.location = "/";
                }

                return token;
            }).catch(reason => window.location = "/");
    }

    getToken = () => {

        return this.auth.currentUser
            .getIdToken()
            .then((token) => {

                if(!token) {
                    window.location = "/";
                }

                return token;
            }).catch(reason => window.location = "/");
    }
}

// export const authService = process.env.NODE_ENV === "production" ? new AuthService() : new MockAuthService();
export const authService = new AuthService();
